export default function PreLoader() {
    return (
        <div className='preloader-container'>
            <div className="content">
                <img className='preloader-image' src={require('../assets/images/character.png')} width={80}/>
                <img className='preloader-rotate' src={require('../assets/images/leaves.png')} width={280}/>
            </div>
        </div>
    )
}
