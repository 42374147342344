import React, { Suspense, lazy } from 'react';
import './styles/App.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactDOM from 'react-dom/client';
import PreLoader from './Layouts/PreLoader';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import 'aos/dist/aos.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'sweetalert2/src/sweetalert2.scss'


const App = lazy(() => import('./App'));

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={<PreLoader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);
